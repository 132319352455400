"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionCreators = exports.ActionTypes = void 0;
var ActionTypes = {
  UNDO: '@@redux-undo/UNDO',
  REDO: '@@redux-undo/REDO',
  JUMP_TO_FUTURE: '@@redux-undo/JUMP_TO_FUTURE',
  JUMP_TO_PAST: '@@redux-undo/JUMP_TO_PAST',
  JUMP: '@@redux-undo/JUMP',
  CLEAR_HISTORY: '@@redux-undo/CLEAR_HISTORY'
};
exports.ActionTypes = ActionTypes;
var ActionCreators = {
  undo: function undo() {
    return {
      type: ActionTypes.UNDO
    };
  },
  redo: function redo() {
    return {
      type: ActionTypes.REDO
    };
  },
  jumpToFuture: function jumpToFuture(index) {
    return {
      type: ActionTypes.JUMP_TO_FUTURE,
      index: index
    };
  },
  jumpToPast: function jumpToPast(index) {
    return {
      type: ActionTypes.JUMP_TO_PAST,
      index: index
    };
  },
  jump: function jump(index) {
    return {
      type: ActionTypes.JUMP,
      index: index
    };
  },
  clearHistory: function clearHistory() {
    return {
      type: ActionTypes.CLEAR_HISTORY
    };
  }
};
exports.ActionCreators = ActionCreators;