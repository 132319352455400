"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = undoable;

var debug = _interopRequireWildcard(require("./debug"));

var _actions = require("./actions");

var _helpers = require("./helpers");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

function createHistory(state, ignoreInitialState) {
  // ignoreInitialState essentially prevents the user from undoing to the
  // beginning, in the case that the undoable reducer handles initialization
  // in a way that can't be redone simply
  var history = (0, _helpers.newHistory)([], state, []);

  if (ignoreInitialState) {
    history._latestUnfiltered = null;
  }

  return history;
} // insert: insert `state` into history, which means adding the current state
//         into `past`, setting the new `state` as `present` and erasing
//         the `future`.


function insert(history, state, limit, group) {
  var lengthWithoutFuture = history.past.length + 1;
  debug.log('inserting', state);
  debug.log('new free: ', limit - lengthWithoutFuture);
  var past = history.past,
      _latestUnfiltered = history._latestUnfiltered;
  var isHistoryOverflow = limit && limit <= lengthWithoutFuture;
  var pastSliced = past.slice(isHistoryOverflow ? 1 : 0);
  var newPast = _latestUnfiltered != null ? [].concat(_toConsumableArray(pastSliced), [_latestUnfiltered]) : pastSliced;
  return (0, _helpers.newHistory)(newPast, state, [], group);
} // jumpToFuture: jump to requested index in future history


function jumpToFuture(history, index) {
  if (index < 0 || index >= history.future.length) return history;
  var past = history.past,
      future = history.future,
      _latestUnfiltered = history._latestUnfiltered;
  var newPast = [].concat(_toConsumableArray(past), [_latestUnfiltered], _toConsumableArray(future.slice(0, index)));
  var newPresent = future[index];
  var newFuture = future.slice(index + 1);
  return (0, _helpers.newHistory)(newPast, newPresent, newFuture);
} // jumpToPast: jump to requested index in past history


function jumpToPast(history, index) {
  if (index < 0 || index >= history.past.length) return history;
  var past = history.past,
      future = history.future,
      _latestUnfiltered = history._latestUnfiltered;
  var newPast = past.slice(0, index);
  var newFuture = [].concat(_toConsumableArray(past.slice(index + 1)), [_latestUnfiltered], _toConsumableArray(future));
  var newPresent = past[index];
  return (0, _helpers.newHistory)(newPast, newPresent, newFuture);
} // jump: jump n steps in the past or forward


function jump(history, n) {
  if (n > 0) return jumpToFuture(history, n - 1);
  if (n < 0) return jumpToPast(history, history.past.length + n);
  return history;
} // helper to dynamically match in the reducer's switch-case


function actionTypeAmongClearHistoryType(actionType, clearHistoryType) {
  return clearHistoryType.indexOf(actionType) > -1 ? actionType : !actionType;
} // redux-undo higher order reducer


function undoable(reducer) {
  var rawConfig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  debug.set(rawConfig.debug);

  var config = _objectSpread({
    limit: undefined,
    filter: function filter() {
      return true;
    },
    groupBy: function groupBy() {
      return null;
    },
    undoType: _actions.ActionTypes.UNDO,
    redoType: _actions.ActionTypes.REDO,
    jumpToPastType: _actions.ActionTypes.JUMP_TO_PAST,
    jumpToFutureType: _actions.ActionTypes.JUMP_TO_FUTURE,
    jumpType: _actions.ActionTypes.JUMP,
    neverSkipReducer: false,
    ignoreInitialState: false,
    syncFilter: false
  }, rawConfig, {
    initTypes: (0, _helpers.parseActions)(rawConfig.initTypes, ['@@redux-undo/INIT']),
    clearHistoryType: (0, _helpers.parseActions)(rawConfig.clearHistoryType, [_actions.ActionTypes.CLEAR_HISTORY])
  }); // Allows the user to call the reducer with redux-undo specific actions


  var skipReducer = config.neverSkipReducer ? function (res, action) {
    for (var _len = arguments.length, slices = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      slices[_key - 2] = arguments[_key];
    }

    return _objectSpread({}, res, {
      present: reducer.apply(void 0, [res.present, action].concat(slices))
    });
  } : function (res) {
    return res;
  };
  var initialState;
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    debug.start(action, state);
    var history = state;

    for (var _len2 = arguments.length, slices = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
      slices[_key2 - 2] = arguments[_key2];
    }

    if (!initialState) {
      debug.log('history is uninitialized');

      if (state === undefined) {
        var createHistoryAction = {
          type: '@@redux-undo/CREATE_HISTORY'
        };
        var start = reducer.apply(void 0, [state, createHistoryAction].concat(slices));
        history = createHistory(start, config.ignoreInitialState);
        debug.log('do not set initialState on probe actions');
        debug.end(history);
        return history;
      } else if ((0, _helpers.isHistory)(state)) {
        history = initialState = config.ignoreInitialState ? state : (0, _helpers.newHistory)(state.past, state.present, state.future);
        debug.log('initialHistory initialized: initialState is a history', initialState);
      } else {
        history = initialState = createHistory(state, config.ignoreInitialState);
        debug.log('initialHistory initialized: initialState is not a history', initialState);
      }
    }

    var res;

    switch (action.type) {
      case undefined:
        return history;

      case config.undoType:
        res = jump(history, -1);
        debug.log('perform undo');
        debug.end(res);
        return skipReducer.apply(void 0, [res, action].concat(slices));

      case config.redoType:
        res = jump(history, 1);
        debug.log('perform redo');
        debug.end(res);
        return skipReducer.apply(void 0, [res, action].concat(slices));

      case config.jumpToPastType:
        res = jumpToPast(history, action.index);
        debug.log("perform jumpToPast to ".concat(action.index));
        debug.end(res);
        return skipReducer.apply(void 0, [res, action].concat(slices));

      case config.jumpToFutureType:
        res = jumpToFuture(history, action.index);
        debug.log("perform jumpToFuture to ".concat(action.index));
        debug.end(res);
        return skipReducer.apply(void 0, [res, action].concat(slices));

      case config.jumpType:
        res = jump(history, action.index);
        debug.log("perform jump to ".concat(action.index));
        debug.end(res);
        return skipReducer.apply(void 0, [res, action].concat(slices));

      case actionTypeAmongClearHistoryType(action.type, config.clearHistoryType):
        res = createHistory(history.present, config.ignoreInitialState);
        debug.log('perform clearHistory');
        debug.end(res);
        return skipReducer.apply(void 0, [res, action].concat(slices));

      default:
        res = reducer.apply(void 0, [history.present, action].concat(slices));

        if (config.initTypes.some(function (actionType) {
          return actionType === action.type;
        })) {
          debug.log('reset history due to init action');
          debug.end(initialState);
          return initialState;
        }

        if (history._latestUnfiltered === res) {
          // Don't handle this action. Do not call debug.end here,
          // because this action should not produce side effects to the console
          return history;
        }
        /* eslint-disable-next-line no-case-declarations */


        var filtered = typeof config.filter === 'function' && !config.filter(action, res, history);

        if (filtered) {
          // if filtering an action, merely update the present
          var filteredState = (0, _helpers.newHistory)(history.past, res, history.future, history.group);

          if (!config.syncFilter) {
            filteredState._latestUnfiltered = history._latestUnfiltered;
          }

          debug.log('filter ignored action, not storing it in past');
          debug.end(filteredState);
          return filteredState;
        }
        /* eslint-disable-next-line no-case-declarations */


        var group = config.groupBy(action, res, history);

        if (group != null && group === history.group) {
          // if grouping with the previous action, only update the present
          var groupedState = (0, _helpers.newHistory)(history.past, res, history.future, history.group);
          debug.log('groupBy grouped the action with the previous action');
          debug.end(groupedState);
          return groupedState;
        } // If the action wasn't filtered or grouped, insert normally


        history = insert(history, res, config.limit, group);
        debug.log('inserted new state into history');
        debug.end(history);
        return history;
    }
  };
}